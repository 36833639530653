// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/header/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/header/index.tsx");
  import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { useRootLoader } from '~kiosk/utils/useRootLoader';
import clsx from 'clsx';
export const navLinks = [{
  name: 'Help',
  link: '/'
}];
export function Header() {
  _s();
  const data = useRootLoader();
  const homeLogo = data.activeChannel.customFields?.storeIcon?.preview;
  return <header className={clsx('sticky top-0 z-10', 'bg-black from-zinc-700 to-gray-900 transform shadow')}>
      <div className="max-w-7xl mx-auto px-4 py-1 flex justify-between items-center space-x-4">
        <div className="w-auto mx-auto flex justify-start items-center space-x-8 flex-1">
          <Link to="/" className="w-12">
            <img src={homeLogo} alt="Kiosk Logo" />
          </Link>
          <div className="space-x-12 hidden sm:block">
            {navLinks.map(navLink => <Link className="text-sm md:text-base text-gray-200 hover:text-white" to={navLink.link} prefetch="intent" key={navLink.name}>
                {navLink.name}
              </Link>)}
          </div>
        </div>

        
      </div>
    </header>;
}
_s(Header, "p8SQDOOKcp4gex6Qsmp1aN/e6Zk=", false, function () {
  return [useRootLoader];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;